* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

body {
  align-items: stretch;
  color: #00000d;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-items: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-items: center;
}

/* text */
* {
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

body {
  font-family: -apple-system, sans-serif;
  font-size: 16px;
  letter-spacing: 0.25px;
  line-height: 24px;
}

strong {
  color: #000;
  font-family: monospace;
}

input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #dadadf;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  height: 30px;
  font-family: monospace;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-align: center;
}

textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid #dadadf;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  font-family: monospace;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 24px;
  min-height: 30px;
  text-align: center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
